import 'owl.carousel';

jQuery( document ).ready( function ( $ ) {
	// Nav
	$( 'nav>ul>li>a' ).on( 'click', function ( e ) {
		e.preventDefault();
		const href = $( this ).attr( 'href' );

		if ( href === '#music' || href === '#videos' ) {
			$( 'header h1' ).fadeOut( 400, function () {
				$( 'nav>ul>li:nth-child(2)>a' ).show();
			} );
		} else {
			$( 'header h1' ).fadeIn( 400, function () {
				$( 'nav>ul>li:nth-child(2)>a' ).hide();
			} );
		}

		$( 'main section' ).fadeOut( 400, function () {
			$( href ).fadeIn( 400 );
		} );
	} );

	// Newsletter
	$( 'form' ).on( 'submit', function ( e ) {
		e.preventDefault();
		var data = $( this ).serialize();
		$.ajax( {
			type: 'POST',
			url: 'https://subs.sonymusicfans.com/submit',
			dataType: 'json',
			data: data,
			xhrFields: {
				withCredentials: false
			},
			success: function ( data ) {
				$( 'form' ).html( '<div class="thanks">Thanks For Signing Up!</div>' );
			},
			error: function ( err ) {
				alert( 'An error has occurred!' );
			}
		} );
	} );

	// Videos
	$( '.owl-carousel' ).owlCarousel( {
		items: 4,
		loop: true,
		nav: false,
		dots: false,
		margin: 20,
		responsive: {
			0: {
				loop: true,
				items: 1,
				stagePadding: 40
			},
			768: {
				items: 2,
				loop: true,
				stagePadding: 40
			},
			992: {
				items: 3,
				loop: true,
				stagePadding: 40
			},
			1099: {
				items: 4,
				loop: false
			}
		}
	} );
} );
